<template>
  <div class="upload">
    <div
      class="upload-box"
      v-if="!disabled"
    >
      <el-upload
        ref="upload"
        :multiple="isMultiple"
        :action="actionUrl"
        :show-file-list="false"
        :file-list="fileList"
        :data="uploadData"
        :before-upload="handleBeforeUpload"
        :on-success="handleOnSuccess"
        :on-error="handleOnError"
        :on-change="handleOnChange"
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
          plain
        >
          <span v-if="type === 'image'">上传图片</span>
          <span v-if="type === 'file'">上传附件</span>
        </el-button>
      </el-upload>
      <div
        v-if="type === 'file'"
        class="file-list"
      >
        <div
          v-for="(item,index) in fileList"
          class="file-item"
        >
          {{item.name}}
          <el-button
            type="text"
            @click="deleteFile(index)"
            icon="el-icon-close"
            style="color: #67C23A;margin-left: 10px;"
          ></el-button>
        </div>
      </div>
    </div>
    <div
      v-if="type === 'image'"
      class="img-list"
    >
      <div class="img-item" :style="{width: imgWidth + 'px',height: imgHeight + 'px'}"
        v-for="(image,index) in fileList"
        :key="index"
      >
        <el-image
          class="img"
          :src="image.url"
          fit="contain"
        />

        <div class="img-actions">
          <i
            class="el-icon-zoom-in preview"
            @click="openImageViewer(image)"
          ></i>
          <i
            class="el-icon-delete delete"
            @click="deleteImage(index)"
          ></i>
        </div>
      </div>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeImageViewer"
      :url-list="viewImage"
    />
  </div>
</template>

<script>
import { showLoading, hideLoading } from '@/utils/loading'
import { mapActions } from 'vuex'
import config from '@/config'

export default {
  name: 'upload',
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  props: {
    pjId: 0,
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    type: {
      type: String,
      default () {
        return 'image'
      }
    },
    isMultiple: {
      type: Boolean,
      default () {
        return true
      }
    },
    fileList: {
      type: Array,
      default () {
        return []
      }
    },
    imgWidth:{
      type:Number,
      default(){
        return 140;
      }
    },
    imgHeight:{
      type:Number,
      default(){
        return 140;
      }
    }
  },
  data () {
    return {
      actionUrl: `${config.baseUrl}/api/system.attachment/upload`,
      showViewer: false,
      viewImage: []
    }
  },
  computed: {
    viewImgList () {
      return function (list) {
        return list.map(item => item.url)
      }
    },
    uploadData () {
      let data = {
        token: this.$store.state.user.token,
        pj_id: this.pjId ? this.pjId : 0
      }
      return data
    }
  },
  methods: {
    ...mapActions([
      'clearCache'
    ]),
    handleBeforeUpload (file) {
      showLoading('上传中')
      if (this.type === 'image') {
        const isIMG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isIMG) {
          this.$message.warning('上传图片只支持jpg、jpeg、png格式!')
          hideLoading()
          return false
        }
      } else if (this.type === 'file') {
        hideLoading()
        // const isFILE = file.name.includes('.xls') || file.name.includes('.xlsx')
        // if (!isFILE) {
        //     this.$message.warning('请上传正确的附件格式!')
        //     hideLoading()
        //     return false
        // }
      }
      // if (file.size > 1024 * 1024) {
      //     this.$message.warning('请上传小于1M的文件')
      //     hideLoading()
      //     return false
      // }
    },
    handleOnSuccess (res) {
      if (res.code === 0) {
        this.fileList.push({
          attach_id: res.data.attach_id,
          url: res.data.url,
          name: res.data.name
        })
        this.returnFileInfo()
      } else if (res.code === 500) {
        this.$message.warning(res.msg)
      } else if (res.code === 1000) {
        this.$notify.warning({
          title: '提示',
          message: '需要重新登录'
        })
        this.clearCache()
        this.$router.push({
          name: 'login'
        })
      } else if (res.code === 1001) {
        this.$notify.warning({
          title: '提示',
          message: '登录超时，请重新登录'
        })
        this.clearCache()
        this.$router.push({
          name: 'login'
        })
      } else if (res.code === 1002) {
        this.$notify.warning({
          title: '提示',
          message: '您的账号在别处已经登录，请重新登录'
        })
        this.clearCache()
        this.$router.push({
          name: 'login'
        })
      }
      hideLoading()
    },
    handleOnError (err) {
      hideLoading()
    },
    handleOnChange (e) {

    },
    openImageViewer (image) {
      this.showViewer = true
      this.viewImage = [image.url]
    },
    closeImageViewer () {
      this.showViewer = false
      this.viewImage = []
    },
    deleteFile (index) {
      this.fileList.splice(index, 1)
      this.returnFileInfo()
    },
    deleteImage (index) {
      this.fileList.splice(index, 1)
      this.returnFileInfo()
    },
    returnFileInfo () {
      let fileIds = ''
      if (this.fileList.length === 0) {
        fileIds = ''
      } else if (this.fileList.length === 1) {
        fileIds = this.fileList[0].attach_id
      } else {
        for (let i = 0; i < this.fileList.length; i++) {
          if (i === 0) {
            fileIds = fileIds + this.fileList[i].attach_id
          } else {
            fileIds = fileIds + ',' + this.fileList[i].attach_id
          }
        }
      }
      this.$emit('getFileInfo', fileIds);
      this.$emit('changeFile', this.fileList);
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  .upload-box {
    display: flex;
    align-items: center;

    .file-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: 10px;
      align-items: center;

      .file-item {
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .img-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .img-item {
      position: relative;
      margin: 0 15px 15px 0;
      background-color: #fff;
      border: 1px solid #c0ccda;
      border-radius: 3px;
      width: 140px;
      height: 140px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        position: relative;
        width: 140px;
      }

      .img-actions {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: default;
        text-align: center;
        color: #fff;
        opacity: 0;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;

        .preview,
        .delete {
          font-size: 26px;
          margin: 0 14px;
          cursor: pointer;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
